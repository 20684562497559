import * as React from "react";

interface IRenderErrorMsg {
  errors: any;
  touched: any;
  colorTheme: string;
  name: string;
}

export default (props: IRenderErrorMsg) => {
  if (props.colorTheme == "transparent") {
    return props.errors[props.name] && props.touched[props.name] ? <div className="donErrorMessageWrapper">
      <div className={"donErrorMessage donOpposite" + props.colorTheme}>
        <p>{props.errors[props.name]}</p>
      </div>
    </div> : <></>
  } else {
    return <div className="donErrorMessageWrapper">
      <div
        style={{
          visibility:
            props.errors[props.name] &&
              props.touched[props.name]
              ? "visible"
              : "hidden"
        }}
        className={"donErrorMessage donOpposite" + props.colorTheme}
      >
        <p>{props.errors[props.name]}</p>
      </div>
    </div>
  }

}
