import { Field, FormikErrors, FormikTouched } from "formik";
import * as React from "react";
import { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { ClipLoader } from "react-spinners";
import { useDebounce } from "../../../common/debounce";
import { unCamelCase } from "../../../common/helper/helper";
import { CountryDropdown } from "../../CountryDropDown";
import { DonateFormContactDetailsCheck, FormConstants, REPLACE, States, WaFormConstants } from "../../GenericForm/Components/Constants";
import { IFieldProps } from "../../GenericForm/Components/FormFields";
import FormField, { CustomFormFieldFactory } from "./FormField";
import { getFormDataByEmailaddress } from "./getFormDataByEmailaddress";

interface IStep1Props {
	animClass?: string;
	errors: FormikErrors<unknown>;
	touched: FormikTouched<unknown>;
	values: any;
	setFieldValue: (field: string, value: any) => void;
	colorTheme: string;
	isColumn: boolean;
	enableCountryField: boolean;
	customFields: IFieldProps[];
	findDonorByEmailaddress?: boolean;
	autoCompleted: boolean;
	setAutoCompleted: (autoCompleted: boolean) => void;
	donorData: DonateFormContactDetailsCheck;
	setDonorData: (donorData: DonateFormContactDetailsCheck) => void;
	setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
	isWaForm: boolean;
}

export const stepOneAllFields = [
	FormConstants.firstName,
	FormConstants.lastName,
	FormConstants.address,
	FormConstants.suburb,
	FormConstants.state,
	FormConstants.postcode,
	FormConstants.country,
	FormConstants.phone,
	FormConstants.email
];

export const stepOneCompanyFields = [
	WaFormConstants.companyName,
	WaFormConstants.abn,
	WaFormConstants.companyAddress,
	WaFormConstants.companySuburb,
	WaFormConstants.companyState,
	WaFormConstants.companyPostcode,
	WaFormConstants.contactFirstName,
	WaFormConstants.contactLastName,
	WaFormConstants.contactEmail,
	WaFormConstants.contactPhone
];

export const stepOneStandardFields = [
	FormConstants.firstName,
	FormConstants.lastName,
	FormConstants.address,
	FormConstants.suburb,
	FormConstants.state,
	FormConstants.postcode,
	FormConstants.phone,
	FormConstants.email,
];

export const StepOneFieldFactory: React.FC<IStep1Props & { fieldName: string }> = props => {
	switch (props.fieldName) {
		default:
			return (
				<FormField
					className="form-control"
					type="text"
					name={props.fieldName}
					placeholder={unCamelCase(props.fieldName)}
					errors={props.errors}
					touched={props.touched}
					colorTheme={props.colorTheme}
					isColumn={props.isColumn}
				/>
			);

		case FormConstants.state: {
			const stateList = props.enableCountryField
				? [...States, { value: "Other", text: "Other" }]
				: States;
			return (
				<FormField
					colorTheme={props.colorTheme}
					isColumn={props.isColumn}
					type="text"
					className="form-control standard-form-group"
					name={FormConstants.state}
					component="select"
					placeholder="State*"
					errors={props.errors}
					touched={props.touched}
					children={stateList.map((opt, index) => (
						<option key={index} value={opt.value}>
							{opt.text}
						</option>
					))}
				/>
			);
		}

		case WaFormConstants.companyState: {
			const stateList = props.enableCountryField ? [...States, { value: "Other", text: "Other" }] : States;
			return (
				<FormField
					colorTheme={props.colorTheme}
					isColumn={props.isColumn}
					type="text"
					className="form-control standard-form-group"
					name={WaFormConstants.companyState}
					component="select"
					placeholder="State*"
					errors={props.errors}
					touched={props.touched}
					children={stateList.map((opt, index) => (
						<option key={index} value={opt.value}>
							{opt.text}
						</option>
					))}
				/>
			);
		}

		case FormConstants.country:
			return props.enableCountryField ? (
				<CountryDropdown
					name={FormConstants.country}
					hasError={props.errors[FormConstants.country]}
					isTouched={props.touched[FormConstants.country]}
					isColumn={props.isColumn}
				/>
			) : null;
	}
};
const DonateFormStepOne: React.FC<IStep1Props> = (props: IStep1Props) => {
	const [isFetchingDonorData, setIsFetchingDonorData] = useState<boolean>(false);

	const debouncedEmailAddress = useDebounce(props.values[FormConstants.email], 2000);

	useEffect(() => {
		const fetchDonorData = async () => {
			await getFormDataByEmailaddress(debouncedEmailAddress).then(
				(data: DonateFormContactDetailsCheck) => {
					setIsFetchingDonorData(false);
					if (data !== null) {
						if (props.autoCompleted === false) {
							props.setAutoCompleted(true);
						}
						if (data?.Address === true && props.values[FormConstants.address] === "") {
							props.setFieldValue(FormConstants.address, REPLACE);
							props.setFieldTouched(FormConstants.address, true, true);
						}
						if (
							data?.FirstName === true &&
							props.values[FormConstants.firstName] === ""
						) {
							props.setFieldValue(FormConstants.firstName, REPLACE);
							props.setFieldTouched(FormConstants.firstName, true, true);
						}
						if (
							data?.LastName === true &&
							props.values[FormConstants.lastName] === ""
						) {
							props.setFieldValue(FormConstants.lastName, REPLACE);
							props.setFieldTouched(FormConstants.lastName, true, true);
						}
						if (data?.Phone === true && props.values[FormConstants.phone] === "") {
							props.setFieldValue(FormConstants.phone, REPLACE);
							props.setFieldTouched(FormConstants.phone, true, true);
						}
						if (
							data?.Postcode === true &&
							props.values[FormConstants.postcode] === ""
						) {
							props.setFieldValue(FormConstants.postcode, REPLACE);
							props.setFieldTouched(FormConstants.postcode, true, true);
						}
						if (data?.State === true && props.values[FormConstants.state] === "") {
							props.setFieldValue(FormConstants.state, REPLACE);
							props.setFieldTouched(FormConstants.state, true, true);
						}
						if (data?.Suburb === true && props.values[FormConstants.suburb] === "") {
							props.setFieldValue(FormConstants.suburb, REPLACE);
							props.setFieldTouched(FormConstants.suburb, true, true);
						}
						props.setDonorData(data);
					} else if (props.autoCompleted === true) {
						props.setAutoCompleted(false);
					}
				}
			);
		};
		if (
			props.values[FormConstants.email] !== "" &&
			props.values[FormConstants.email] !== undefined &&
			!props.errors[FormConstants.email] &&
			props.findDonorByEmailaddress
		) {
			setIsFetchingDonorData(true);
			fetchDonorData();
		}
	}, [debouncedEmailAddress]);

	useEffect(() => {
		if (props.autoCompleted) {
			props.setAutoCompleted(false);
			props.setFieldValue(FormConstants.address, "");
			props.setFieldValue(FormConstants.firstName, "");
			props.setFieldValue(FormConstants.lastName, "");
			props.setFieldValue(FormConstants.phone, "");
			props.setFieldValue(FormConstants.postcode, "");
			props.setFieldValue(FormConstants.state, "");
			props.setFieldValue(FormConstants.suburb, "");
		}
	}, [props.values[FormConstants.email]]);

	return (
		<div className={`donStep1 container`}>
			{props.findDonorByEmailaddress && (
				<div className="row">
					<StepOneFieldFactory
						fieldName={FormConstants.email}
						{...props}
						isColumn={true}
					/>
					{props.autoCompleted && (
						<p
							className={"col-md-12 donTaxMessage donOpposite" + props.colorTheme}
							style={{ marginBottom: 0, marginTop: 10 }}
						>
							Welcome back! If your address or phone number has changed since the last
							time you donated,{" "}
							<label
								style={{ textDecoration: "underline", cursor: "pointer" }}
								onClick={() => {
									props.setAutoCompleted(false);
									props.setFieldValue(FormConstants.address, "");
									props.setFieldValue(FormConstants.firstName, "");
									props.setFieldValue(FormConstants.lastName, "");
									props.setFieldValue(FormConstants.phone, "");
									props.setFieldValue(FormConstants.postcode, "");
									props.setFieldValue(FormConstants.state, "");
									props.setFieldValue(FormConstants.suburb, "");
								}}
							>
								click here to update them.
							</label>
						</p>
					)}
				</div>
			)}
			<LoadingOverlay
				active={isFetchingDonorData}
				className={"loadingOverlay"}
				spinner={<ClipLoader sizeUnit={"px"} size={20} color={"white"} />}
			>
				{props.isWaForm ? (
					<div className="row" style={{ justifyContent: "center" }}>
						<Field
							className={"donOpposite" + props.colorTheme}
							component="div"
							style={{ display: "flex" }}
							name={WaFormConstants.entityType}
						>
							<div className="donRadioWrapper form-check">
								<input
									defaultChecked={props.values[WaFormConstants.entityType] === "individual"}
									name={WaFormConstants.entityType}
									type="radio"
									value="individual"
									className="form-check-input"
									id="individual"
									style={{ cursor: "pointer" }}
								/>
								<label className="form-check-label" style={{ cursor: "pointer" }} htmlFor="individual">
									I donate as an individual
								</label>
							</div>
							<div className="donRadioWrapper form-check">
								<input
									defaultChecked={props.values[WaFormConstants.entityType] === "company"}
									name={WaFormConstants.entityType}
									type="radio"
									value="company"
									className="form-check-input"
									id="company"
									style={{ cursor: "pointer" }}
								/>
								<label className="form-check-label" style={{ cursor: "pointer" }} htmlFor="company">
									I donate on behalf of an association or company
								</label>
							</div>
						</Field>
					</div>
				) : null}
				<div className="row">
					{(props.isWaForm && props.values[WaFormConstants.entityType] === "company"
						? stepOneCompanyFields
						: stepOneAllFields
					)
						.filter((field) => {
							if (props.findDonorByEmailaddress && field === FormConstants.email) {
								return false;
							} else if (props.autoCompleted) {
								if (props.donorData && props.donorData?.[field] === false) {
									return true;
								} else {
									return false;
								}
							} else {
								return true;
							}
						})
						.map((f, i) => (
							<StepOneFieldFactory key={i} fieldName={f} {...props} />
						))}
				</div>
			</LoadingOverlay>
			<div className="row">
				{props?.customFields?.map((f, i) => {
					return CustomFormFieldFactory(
						f,
						props.isColumn,
						i,
						props.errors,
						props.touched,
						props.colorTheme,
						props.setFieldValue
					);
				})}
			</div>
		</div>
	);
};

export default DonateFormStepOne;
