import * as React from "react";
import MaskedInput from "react-text-mask";
import { Focused } from 'react-credit-cards';
import Cards from 'react-credit-cards';
import 'react-credit-cards/lib/styles.scss';
import { FormConstants, IDonateFormProps } from "../../GenericForm/Components/Constants";
import { ICheckoutProps } from "./CheckoutFactory";
import { isNullOrWhitespace } from "../../Events/EventList/EventList";
import { BPointTokenRequestModel } from "../../ml_modules/websiteApi/BPointTokenRequestModel";
import { PaymentProvider } from "../../ml_modules/websiteApi/PaymentProvider";
import { BPointCardTokenResponse } from "../../ml_modules/websiteApi/BPointCardTokenRequest";
import { isNullOrWhiteSpace } from "../../../common/helper/helper";
import { FormikActions } from "formik";
import { DonateFormModel } from "../../ml_modules/websiteApi/DonateFormModel";
import { IRcTokenResponse, tokenFailable } from "../../GoogleReCaptcha/Recaptcha";

export default (props: ICheckoutProps) => {
    const generateBody = async (contentId: string, fixedValues: IDonateFormProps, actions: FormikActions<DonateFormModel>, account: string, rcToken: Promise<IRcTokenResponse>) => {
        const TokenRequest: BPointTokenRequestModel = {
            ContentId: contentId,
            Account: account,
            CardName: `${props.values[FormConstants.firstName]} ${props.values[FormConstants.lastName]}`,
            Descrition: "Wa Donation",
            CardNum: number.toString(),
            ExpiryMonth: expiry.toString().substr(0, 2),
            ExpiryYear: expiry.toString().substr(-2),
            PaymentProvider: PaymentProvider.BPoint,
            Errors: null
        }
        const resp = await fetch("/Umbraco/Api/BPoint/CreateBPointToken", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(TokenRequest)
        });
        const token: BPointCardTokenResponse = await resp.json();
        if (!isNullOrWhitespace(token.Error)) {
            return false
        }
        const bPointToken = token.Token;
        const maskedCardNumber = token.MaskedCardNumber;
        const bPointTokenError = token.Error;
        if (isNullOrWhiteSpace(bPointToken) || isNullOrWhiteSpace(maskedCardNumber) || bPointToken === undefined || maskedCardNumber === undefined) {
            props.setSubmitError(bPointTokenError);
            actions.setSubmitting(false);
            return false;
        } else {
            return ({
                ...fixedValues,
                ContentId: contentId,
                SecureToken: bPointToken,
                MaskedCardNumber: maskedCardNumber,
                PaymentProvider: "bpoint",
                RcToken: await tokenFailable(rcToken),
                Account: account
            });
        }
    }
    props.updateTokenGenerator(generateBody);

    const [currentYear, setCurrentYear] = React.useState(0)
    const [currentMonth, setCurrentMonth] = React.useState(0)
    React.useEffect(() => {
        let year = new Date().getFullYear().toString().substr(-2);
        let month = new Date().getMonth() + 1;
        setCurrentYear(Number(year));
        setCurrentMonth(month);
    }, []);

    const [ccNumberError, setCcNumberError] = React.useState(true)
    const [ccExpiryError, setCcExpiryError] = React.useState("");
    const [ccCVCError, setCcCVCError] = React.useState(true);
    const [numberTouched, setNumberTouched] = React.useState(false);
    const [expiryTouched, setExpiryTouched] = React.useState(false);
    const [cvcTouched, setcvcTouched] = React.useState(false);
    const [number, setNumber] = React.useState(0);
    const [expiry, setExpiry] = React.useState("");
    const [cvc, setCVC] = React.useState(0);
    const [focus, setFocus] = React.useState<Focused>("number");
    React.useEffect(() => {
        props.setComplete(checkComplete)
    }, [ccNumberError, ccExpiryError, ccCVCError, numberTouched, expiryTouched, cvcTouched, number, expiry, cvc]);
    const checkComplete = () => {
        if ((ccNumberError && numberTouched) || (ccExpiryError !== "" && expiryTouched) || (ccCVCError && cvcTouched) || !number && !cvc && !expiry) {
            return false
        } else {
            if (props.setExpiryMonth) props.setExpiryMonth(expiry.toString().substr(0, 2));
            if (props.setExpiryYear) props.setExpiryYear(expiry.toString().substr(2, 4));
            return true
        }
    }

    const validateSetExpiryDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.replace(/\D/g, '').length < 4) {
            setCcExpiryError("Expiry date requires 4 digits MM/YY");
        } else if (Number(e.target.value.replace(/\D/g, '').toString().substr(0, 2)) > 12) {
            setCcExpiryError("Expiry date month cannot be greater than 12");
        } else if (Number(e.target.value.replace(/\D/g, '').toString().substr(-2)) < currentYear) {
            setCcExpiryError("Expiry date cannot be in the past");
        } else if (Number(e.target.value.replace(/\D/g, '').toString().substr(-2)) === currentYear && Number(e.target.value.replace(/\D/g, '').toString().substr(0, 2)) < currentMonth) {
            setCcExpiryError("Expiry date cannot be in the past");
        } else {
            setCcExpiryError("");
        }
        setExpiry(e.target.value.replace(/\D/g, ''))
    }

    return (
        <div className="checkout">
            <div className="bPointCardWrapper">
                <Cards
                    number={number}
                    name={`${props.values[FormConstants.firstName]} ${props.values[FormConstants.lastName]}`}
                    expiry={expiry}
                    cvc={cvc}
                    focused={focus}
                    callback={(issuer, isValid) => {
                        if (!isValid && number) {
                            setCcNumberError(true)
                        } else {
                            setCcNumberError(false)
                        }
                    }}
                />
                <div className="bPointCardInputs">
                    <MaskedInput mask={[/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/]}
                        name="cardnumber"
                        autoComplete="cc-number"
                        guide={false}
                        className={`bPointField ${ccNumberError && numberTouched ? 'bPointFieldError' : ''}`}
                        type="text"
                        placeholder="Credit card number"
                        onChange={(e) => {
                            setNumberTouched(true)
                            setFocus("number")
                            setNumber(Number(e.target.value.replace(/\D/g, '')))
                        }}
                    />
                    <MaskedInput mask={[/\d/, /\d/, '/', /\d/, /\d/]}
                        name="exp-date"
                        autoComplete="cc-exp"
                        guide={true}
                        placeholder="Expiry date"
                        className={`bPointField ${ccExpiryError && expiryTouched ? 'bPointFieldError' : ''}`}
                        type="text"
                        onChange={(e) => {
                            setExpiryTouched(true)
                            setFocus("expiry")
                            validateSetExpiryDate(e)
                        }}
                    />
                    <MaskedInput mask={[/\d/, /\d/, /\d/, /\d/]}
                        autoComplete="cc-csc"
                        name="cvc"
                        guide={false}
                        className={`bPointField ${ccCVCError && cvcTouched ? 'bPointFieldError' : ''}`}
                        type="text"
                        placeholder="CVC"
                        onChange={(e) => {
                            setcvcTouched(true)
                            setFocus("cvc")
                            setCVC(Number(e.target.value))
                            if (e.target.value.toString().length < 3) {
                                setCcCVCError(true)
                            } else {
                                setCcCVCError(false)
                            }
                        }}
                    />
                </div>
            </div>
            <div className="bPointError">
                {(ccNumberError && numberTouched) && <p>Invalid credit card number</p>}
                {(ccExpiryError !== "" && expiryTouched) && <p>{ccExpiryError}</p>}
                {(ccCVCError && cvcTouched) && <p>Invalid CVC</p>}
                {!number && !cvc && !expiry && <p>Please enter your card details</p>}
            </div>
        </div>
    )
}