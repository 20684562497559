import * as React from 'react';
import Select from "formik-antd/es/select"
import "formik-antd/es/select/style/css"
import countryList from "./country-list.json"
import "./style.scss"

const Option = Select.Option

interface ICountryDropdownProps {
  name: string
  className?: string
  isColumn: boolean
  isTouched: boolean
  hasError: boolean
}

export const CountryDropdown: React.FC<ICountryDropdownProps> = props => <Select name={props.name}
  dropdownClassName="country-dropdown"
  placeholder="Please select your country"
  showSearch
  optionFilterProp="children"
  filterOption={(input, option) =>
    option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
  }
  className={`country-dropdown-field ${getCountryDropdownExtraClassName(props.hasError, props.isTouched, props.isColumn)} ${props?.className}`}
>
  {countryList && countryList.length > 0 && countryList.map((c, i) =>
    <Option key={i} value={c.label}>{c.label}</Option>)}
</Select>

const getCountryDropdownExtraClassName = (hasErrors: boolean, isTouched: boolean, isColumn: boolean) => {
  let className = "my-2 "
  className += isColumn ? "is-column col-md-12 " : "px-3 col-md-8 ";
  className += isTouched && hasErrors ? "error" : ""
  return className
}