import "../../common/polyfills"
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import DonateForm, { StripelessDonateForm } from './Components/DonateForm';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { IDonateFormProps } from "../GenericForm/Components/Constants";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

interface IDonateFormWrapperProps extends IDonateFormProps {
    publishableKey: string
    paymentProvider: string
}

const DonateFormWrapper = (props: IDonateFormWrapperProps) => {
  return <GoogleReCaptchaProvider reCaptchaKey={props.reCaptchaSiteKey}>
    {props.paymentProvider === "stripe" && props.publishableKey ? <StripeProvider
      apiKey={props.publishableKey}>
      <Elements>
        <DonateForm {...props} />
      </Elements>
    </StripeProvider> : <StripelessDonateForm {...props} />}
  </GoogleReCaptchaProvider>
}

function MountDonateForm (props: IDonateFormWrapperProps, el: Element) {
  ReactDOM.render(<DonateFormWrapper {...props} />, el);
}

(window as any).MountDonateForm = MountDonateForm;


