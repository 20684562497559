export interface IRcTokenResponse {
    success: boolean
    token?: string
    errorMsg?: string
}

type getRcToeknFun = (actionName: string, executeRecaptchaFunc: (actionName: string) => Promise<string>) => Promise<IRcTokenResponse>

//set your local tunnel to https://web-platform.localtunnel.me/ via powershell 
        //  iisexpress-proxy  52600 to 3000
        //  lt -p 3000 --subdomain web-platform

//put the hook 
//const { executeRecaptcha } = useGoogleReCaptcha()
//inside of the body of a function component
//const { executeRecaptcha } = useGoogleReCaptcha();
//get the token inside your submitFunction
//const rcToken = await getRcToken({YourActionName}, executeRecaptcha);
export const getRcTokenAsync: getRcToeknFun = async (actionName: string, executeRecaptchaFunc: (actionName: string) => Promise<string>) => {
    try {
        const token = await executeRecaptchaFunc(actionName)
        return {
            success: true,
            token: token
        };
    } catch (e) {
        return {
            success: false,
            errorMsg: e
        }
    }
}

export interface IReCaptCha {
    reCaptchaSiteKey : string
}

export const tokenFailable = async (tokenResponse: Promise<IRcTokenResponse>) => {
    const rcToekn = await tokenResponse
    return rcToekn && rcToekn.success && rcToekn.token && rcToekn.token ? rcToekn.token : null
}
    