import * as React from "react";
import { CardElement } from "react-stripe-elements";
import { FormConstants } from "../../GenericForm/Components/Constants";
import { ICheckoutProps } from "./CheckoutFactory";
import { FormikActions } from "formik";
import { IRcTokenResponse, tokenFailable } from "../../GoogleReCaptcha/Recaptcha";


export default (props: ICheckoutProps) => {
    const generateBody = async (contentId: number, fixedValues: any, actions: FormikActions<any>, account: string, recaptChaToken: Promise<IRcTokenResponse>) => {
        const { token } = await props.stripe.createToken({
            name: `${fixedValues[FormConstants.firstName]} ${
                fixedValues[FormConstants.lastName]
                }`
        });
        if (token !== undefined) {
            return {
                ...fixedValues,
                ContentId: contentId,
                SecureToken: token.id,
                RcToken: await tokenFailable(recaptChaToken)
            };
        } else {
            return false;
        }
    }
    if (props.updateTokenGenerator) props.updateTokenGenerator(generateBody);
    const [error, setError] = React.useState("");
    let stripeChangeHandler = (event: stripe.elements.ElementChangeResponse) => {
        if (event.error && !event.complete) {
            setError(event.error.message);
        } else if (!event.error && event.complete) {
            setError("");
        }
        props.setComplete(event.complete);
    };
    return (
        <div className="checkout">
            <p className={"checkoutMessage donOpposite" + props.colorTheme}>
                Please enter your card details below:
        </p>
            <CardElement
                className="stripeElements"
                hidePostalCode={true}
                onChange={stripeChangeHandler}
            />
            {error ? (
                <div className="checkoutError">{error}</div>
            ) : (
                    <div className="checkoutError" />
                )}
        </div>
    )
}